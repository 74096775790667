
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@700&display=swap');

:root {
  --background-1: #FCFCFF;
  --background-2: #F2F2F7;
  --background-3: #dbe1ec;
  --background-4: #DBDCE6;
  --background-5: #CFD0DC;
  --background-6: #C5C5D4;
  --bitblue:  #4C71E6;
  --bitpurple: #8F4CE6;
}

.bg1 {
  background-color: var(--background-1);
}

.bg2 {
  background-color: var(--background-2);
}

.bg3 {
  background-color: var(--background-3);
}

.bg4 {
  background-color: var(--background-4);
}

.bg5 {
  background-color: var(--background-5);
}

.bg1 {
  background-color: var(--background-1);
}


.App {
  background-image: linear-gradient(#fcfefe, #c1c4db);
  height: 100%;
  min-height: 100vmax
}

.App-link {
  color: #0d657e;
}

html, body {
  height: 100vh;
}

.clickable {
  cursor: pointer;
}

.subform {
  padding: 1.5em;
  background-color: var(--background-2);;
  color:rgb(37, 37, 42);
  margin: 1em 1em;
  border-radius: 8px;
  border: 1px solid lightslategray;
}

.results {
  background-color: var(--background-2);
  color:rgb(22, 29, 35);
  padding: 1em;
  margin: 1em;
  border: 1px solid lightslategray;
  border-radius: 8px;
  display: block;
}

.paper {
  background-color: var(--background-1);
  color:rgb(10, 13, 16);
  padding: 1em;
  margin: 1em;
  border: 1px solid lightslategray;
  border-radius: 8px;
}

.selects {
  padding: 0.5em;
  margin: 0.5em;
  font-family: monospace;
}

.paper .tab {
  margin-left: 2em;
}

.folder {
  border-width: 0.5px;
  border-style: solid;
  border-color: darkgrey;
  border-radius: 3px;
  background-color: rgb(212, 212, 228);
  color:rgb(72, 66, 65);
  padding: 0px 3px
}

.reftool {
  border: solid 1px blue;
  border-radius: 5px;
  background-color: rgb(156 193 255);
}

.text-contrast {
   color: #4a5769;
}

.bitlug-text {
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 700;
  font-size: 5vw;
  color: var(--bitblue);
  margin-top: 2vh;
}
.bitlug-logo {
  font-family: 'Comfortaa', sans-serif;
  font-weight: 300;
  font-size: 3vw;
  color: var(--bitpurple);
}

.herobox {
  margin-left: 5vw;
  box-sizing: border-box;
  border: none;
  background-color: var(--background-3);
  border-radius: 18px;
  padding: 3em;
  width: 85vw;
  align-items: center;
  color: var(--bitblue);
  border: solid 1px var(--background-4);
}

.toolboxContainer {
  width: 100vh;
  float: left;
  position: fixed;
}
 
.toolboxImage {
  max-width: 100%;
}

.colFullHeight {
  height: 80vh;
}

.text-conflict {
  background-color: rgb(252 222 222);
  color: rgb(192 32 32)
}
.roundBox {
  border-radius: 20px;
}

.splashBackground {
  background-color: var(--bitblue);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: -24px;
  margin-right: -80px;
  height: 105vmax;
}

.splashText {
  background-color: rgb(225, 239, 250);
  align-items: center;
}

.stackFiller {
  height: 25vh;
}

.spashStripe {
  background-color: rgb(225, 239, 250);
}

.splashImg {
  width: 55vw;
  display: block;
  align-content: center;
  text-align: center;

}

.text-outline {
  -webkit-text-stroke: 0.5px rgb(225, 239, 250);
}

.text-deny {
  background-color: rgb(242, 212, 222);
  color: rgb(128, 10, 10)
}

.parent {
  background-color: var(--background-4);
  margin: 0.5em;
  padding: 0.5em;
  border: 2px rgb(59, 59, 88) solid;
  border-radius: 8px;
  align-self: center;
  text-align: center;
  text-overflow: ellipsis;
  min-width: 15em;
}

.footnote {
  display:flex;
  justify-content: space-between;
  flex-direction: row;
  font-size: 12px;
  padding: 0px;
  border: 0px;
  text-align: right;
}

.centersub {
  padding: .5px;
  box-shadow: 0px 5px 10px rgb(192, 192, 190);
}

.sibling {
  background-color: var(--background-5);
  margin: 0.5em;
  padding: 1em;
  border: 2px rgb(59, 59, 88) solid;
  border-radius: 5px;
  align-self: center;
  text-align: center;
  font-size: small;
  min-width: 12em;
  overflow: hidden;
}

.spouse {
  background-color: white;
  margin: 0.5em;
  padding: 2em 1em;
  border: 2px rgb(60, 60, 142) solid;
  border-radius: 5px;
  align-self: center;
  text-align: center;
  font-size: small;
  min-width: 12em;
  overflow: hidden;
}

.spouse:hover {
    background-color: aquamarine;
    color: darkblue;
    cursor: pointer;
    align-content: right;

}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}

.element {
  background-color: white;
  margin: 0.5em;
  padding: 1em;
  border: 2px rgb(74, 74, 172) solid;
  border-radius: 5px;
  align-self: center;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  min-width: 15em;
  min-height: fit-content;
}

.label {
  padding-right: 2em;
  font-weight: 700;
  min-width: 100px;
}

.rndBtn {
  border-radius: 15px;
  color: blue;
}

.value {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: left;
  color:rgb(44, 47, 203);
  padding-left: .75em;
  padding-right: 1.25em;
  margin-bottom: 1em;
}

.err {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: left;
  color:rgb(82, 36, 18);
  background-color: rgb(242, 230, 230);
  padding: 1em;
  margin-bottom: 1em;
  margin: 1em;
  border: solid 1px rgb(217, 9, 9);
  border-radius: 5px;

}

.text-bg-white {
  background-color: var(--background-1);
  padding: 3px;
  margin-left: -3px;
}

.msg {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: left;
  color:rgb(82, 36, 18);
  background-color: rgb(230, 230, 242);
  padding: 1em;
  margin: 1em;
  border: solid 1px rgb(9, 9, 217);
  border-radius: 5px;
}

.title {
  display: inline-block;
  text-align: center;
  align-self: center;
  font-size: large;
}

.kidrow {
    display: flex;
    justify-content: center;
    flex-grow: 1;
}

.kids {
    color: rgb(26, 30, 59);
    text-align: center;
    overflow: hidden;
    padding: .5em;
    border: .5px rgb(20, 63, 77) solid;
    background-color: var(--background-3);
    border-radius: 5px;
    margin: 2px;
    font-size: small;
    width: 10em
}

.kids:hover {
    background-color: aquamarine;
    color: darkblue;
    cursor: pointer;
    overflow: visible;
    width: fit-content;
    min-width: 10em
}

.parent:hover {
    background-color: aquamarine;
    color: darkblue;
    cursor: pointer;
}

.sibling:hover {
    background-color: aquamarine;
    color: darkblue;
    cursor: pointer;
}

.logo {
  transform: scale(0.6, 0.6);
  min-width: 90px;
  min-height: 110px;
  margin-left: .5em;
  color: #273037;
  margin: -0.65em 0.35em;
  padding: 0.5em;
}

.logo:hover {
  box-shadow: 0px 0px 15px 8px rgb(90, 129, 138);
  border-radius: 1em;
}

.footer {
  height: 2em;
  width: 100%;
  position: fixed;
  bottom: 0;
  background: var(--background-6)
}

.topborder {
  width: 100%;
  background-color: var(--background-3);
  z-index: 100
}

.mainContainer {
  padding: 1.5em;
  border-bottom: black 1px groove;
  margin-left: 66px;
  width: 96%
}

.border-split {
  border-bottom: blue 2px dashed;
}

.docs {
  color: rgb(37, 37, 42);
  padding: 1.5em;
}

code {
  color: rgb(23, 68, 93);
  text-overflow: ellipsis;
  white-space: pre;
  font-family: var(--bs-font-monospace)!important;
  font-size: 1em !important;
}

.textStamp {
  background-color: rgb(232, 232, 240);
  padding: 0.5em 0.35em;
  margin: 0.25em 0.5em;
  border-radius: 0.5em;
  border: solid  0.2px;
  border-color: rgb(57, 94, 103) black black rgb(57, 94, 103);
}

.min70 {
  min-width: 15em;
}

.bitmap {
  display: flex;
  width: 100%;
  justify-content: center;
}

.container input {
  border-color: #0d657e;

}

.maxsvg {
  max-width: 1040px;
  min-height: 60px;
}

.blbutton {
  background-color: rgb(76 115 230);
  margin-top: 1em;
  overflow: clip;
}

.blbutton:hover {
  color: rgb(27,27,27)
}

.clrbutton {
  margin-left: 15px;
  margin-top: 1em;
  background-color: darkgray;
  color: rgb(27,27,27)
}

.clrbutton:hover {
  color: lightgray;
  background-color: rgb(47, 46, 52);
}

.border-bl {
  border-color: rgb(63, 65, 163)!important;
  border-top: solid 2px;
  border-bottom: dashed 0.5px grey !important;
}

.elipse {
  width: 1em;
  display: inline-flex;
  overflow: hidden;
}

.blbutton:hover {
  background-color: rgb(116 153 243);
}

.mt {
  margin-top: 3em
}

.mr {
  margin-right: 3em
}

.bordered {
  border-bottom: solid 2px rgb(9, 98, 98);
  background-color: #ffffff;
}

.upperline {
  border-top: solid 2px rgb(55, 55, 79)!important;
}

.toolcard {
  cursor: pointer;
  background-color: var(--background-1) !important;
  box-shadow: 3px 3px 10px 10px rgba(164, 188, 231, 0.7);
}

.inline {
  display: inline-flex;
  color: rgb(40, 51, 54);
}

.toolcard:hover {
  box-shadow: 0px 0px 17px 10px rgb(131, 171, 219);
  background-color: rgb(255, 255, 255);
}

.toolcard:active {
  box-shadow: 0px 0px 4px 4px rgb(106, 209, 235);
}

.full-panel {
    margin: 1em;
    padding: 1em;
    font-weight: 400;
    border: 2px solid rgb(166, 168, 169);
    border-radius: 8px;
    background-color: #f4f4fc;
    color: rgb(41, 53, 53);
  }

.half-panel {
  margin: 1em;
  padding: 1em;
  font-weight: 400;
  border: 2px solid rgb(166, 168, 169);
  border-radius: 8px;
  background-color: #fcfcff;
  color: rgb(20, 31, 157);
}

.ladeda {
  width: 1em;
  animation-duration: 1s;
  animation-name: ladeda;
  animation-iteration-count: infinite;
}

@keyframes ladeda {
  from {
    padding-left: 0em
  }

  to {
    padding-left: 1em;
  }
}

.bitText {
  font-family: monospace;
  stroke: none;
  fill: rgb(42, 36, 36);
  font-size: 6px;
}

.classNameText {
  font-family: monospace;
  stroke: none;
  fill: rgb(2, 90, 90);
  font-size: 12px;
}

.errMsg {
  background-color: rgb(202, 199, 199);
  color: rgb(113, 2, 2);
  margin: 2em;
  padding: 1em;
  border: .1em solid rgb(59, 58, 58);
  font-size: larger;
  width:fit-content
}

.topoMap {
  max-height: 624px;
  overflow-y: auto;
  max-width: 624px;
  margin-top: 12px;
}

.shiftLeft {
  margin-left: -1.5em;;
}

.topoPanel {
  fill: rgb(211, 208, 227);
  stroke-width: 0.5px;
  stroke: rgb(47, 51, 55);
}

.topoCircle {
  stroke: black;
}

.linetip {
  font-family: 'Courier New', Courier, monospace;
  font-size: .25em;
  fill: white;
  fill-opacity: 1;
}

.hidetip {
  fill-opacity: 0;
  animation: normal;
  animation-name: fademeout;
  animation-duration: 2s;
}

@keyframes fademeout {
  from {
    fill-opacity: 1;
  }
  80% {
    fill-opacity: .9;
  }
  to {
    fill-opacity: 0;
  }
}
  .text-blue {
    color: rgb(27, 27, 221);
    font-weight: 600;
  }

  .text-lblue {
    color: rgb(47, 47, 221);
    font-weight: 300;
  }

  .text-green {
    color: rgb(23, 119, 41);
    font-weight: 600;
  }

  .text-lgreen {
    color: rgb(41, 143, 60);
    font-weight: 300;
  }

  .text-grey {
    color: rgb(71, 62, 62);
  }

  .text-bold {
    font-weight: bold;
  }

  .height-limit {
    max-height: 65vh;
    scrollbar-color: rgb(37, 160, 109);

  }

  .bottomGutter {
    height: 200px;
  }

  .flex-split {
    display: flex;
    justify-content: space-between;
  }

  .addtab {
    margin-bottom: -5px;
    margin-right: 1em;
    transform: scaleX(155%);
    border: none;
  }

  .gameMenu {
    fill: rgb(136 173 253);
    border: .1em solid lightgray;
    box-shadow: 3px 3px 10px 10px rgba(181, 174, 174, 0.7);
  }

  .svgMenuItem {
    fill: rgb(168, 168, 170);
  }

  .svgMenuItem:hover {
    fill: lightgray;
  }

  .svgMenuBorder {
    stroke: darkslategray;
  }

  .gameBorder {
    border: rgb(28, 126, 206) solid 1.5px;
    border-radius: 10px;
    padding: 1em;
    background-color: rgb(190, 212, 236);
  }

  .bonded {
    fill: rgb(30, 82, 194) !important;
    stroke: orange !important;
  }

  .part {
    stroke: rgb(4, 249, 225);
    stroke-width: 2px;
    fill: rgb(69, 71, 122);
  }

  .finish {
    fill: rgb(18, 211, 128);
    stroke: darkblue;
  }

  .sidefiller {
    height: 100%;
    background-color: var(--background-2);;
    width: 90px;
    position: fixed;
    left: 0;
    top: 0;
    border-right: 1px solid grey;
    z-index: 0;
  }

  .sidebar {
    width: 90px;
    min-height: 1254px;
    position: sticky;
    left: 0;
    float: left;
    top: 0;
    margin-top: 0px;
    padding-top: 40px;
    border-right: 1px solid grey;
    background-color: var(--background-2);
  }

  .sidebar div {
    padding: 8px;
    text-align: center;
    display: block;
    align-content: center;
    overflow: hidden;
  }
  
  .sidebar :hover {
    background-color: #CFD7EA;
    border-radius: 6px;
  }

  .active {
    background-color: var(--background-3);
    border-color: darkgrey;
    border-width: 1px;
    border-radius: 6px;
  }

  .splash {
    margin-top: -160px;
    margin-left: -70px;
    margin-right: -70px;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    background-color: grey;
    float: left;
    display: flex;
  }

  .chart_canvas {
    position: absolute;
    width: 100%;
    height: 100vh;
    pointer-events: none;
  }

  .graphCanvas {
    width: 57vw;
  }

  .treeGraphic {
    position: absolute;
  }

  .colHeader {
    background-color: rgb(213, 223, 245);
    padding: .5em;
    color: blue;
    border-radius: 5px;
  }

  .shiftleft {
    margin-left:-16.5px;
    margin-top: -16.5px;
  }

  .slider-panel {
    background-color: rgb(235, 239, 249);
    margin: 1em;
    padding: 1em;
    border: 0.5px solid grey;
    border-radius: 10px;
  }

  .padleft {
    padding-left: 2em;
  }

  .templateEx {
    background-color: var(--background-3);
    padding: 10px;
    width: 50%;
    border-radius: 8px;
    font-weight: 550;
  }
  .hoverFill {
    fill: rgb(242, 252, 255);
    stroke: grey
  }
  .hoverFill:hover {
    fill: rgb(116, 153, 243);
  }

  .fluidoffset { 
    margin-left: 8em;
    padding-right: 10em; 
  }

  .quiz {
    display: inline-flex;
    padding-top: 6px;
  }

  .correct {
    background-color: lime;
    border-radius: 8px;
  }

  .wrong {
    background-color: red;
    border-radius: 8px;
  }

  .quizLabel {
    padding: 12px;
    margin: 5px;
    background-color: rgb(200, 200, 255);
    border-radius: 3px;
    border: 1px solid grey;
    
  }

  .quizChoiceSelected {
    border-radius: 5px;
    border: 1px solid grey;
    background-color: rgb(232, 232, 255);
    font-weight: 600;
    margin: 4px;
    height: 73px;
    min-width: 250px;
  }

  .quizLabel:hover {
    background-color: rgb(133, 133, 249);
    color: rgb(255,255,240);
  }

  .qlclicked {
    padding: 12px;
    margin: 5px;
    border-radius: 3px;
    border: 1px solid grey;
    background-color: rgb(133, 133, 249);
    color: rgb(255,255,240);
  }

  .freeChoice {
    margin: 5px;
    height: 73px;
    width: 90%;
  }

  .quizChoice {
    padding: 12px;
    margin: 5px;
  }

